import React from "react"
import {Link} from "react-router-dom"
import {Button} from "antd"
import {logEvent} from "firebase/analytics"
import {analytics} from "../utils/firebase"

export const ButtonDemo: React.FC<any> = (
    {
        style
    }
) => {
    return (
        <Link to={'https://calendly.com/xavier_boschmonart/30min'} target="_blank">
            <Button type="primary" size="large"
                    onClick={() => {
                        logEvent(analytics, 'goal_completion', {
                            name: 'book_demo'
                        })
                    }
                    }
                    style={{
                        fontSize: '20px', width: '300px', height: '100px',
                        fontWeight: '20',
                        ...style
                    }}
            >
                Book a demo
            </Button>
        </Link>

    )
}
