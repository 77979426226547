import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom"
import {ButtonDemo} from "./components/ButtonDemo"
import {Col, Row, Typography} from "antd"
import BottomLeftBackround from './background/square.svg';
import PlayerSpike from './background/player_spike.png';
import TopRightBackground from './background/square2.svg';

import ChartPlotData from './icons/chart_plot.png'
import ChartPlot2Data from './icons/chart_plot_2.png'

import MmmIcon from './icons/ai_icon.png'
import CookielessIcon from './icons/cookieless.png'

import {
    BarChartOutlined,
    CloudOutlined,
    DatabaseOutlined,
    DollarOutlined,
    EyeOutlined,
    FundOutlined,
    LineChartOutlined,
    RadarChartOutlined,
    RiseOutlined,
    SafetyOutlined,
    SolutionOutlined
} from '@ant-design/icons';
import {blockGap, colorPrimary} from "./global"
import {useIsMobile} from "./components/LayoutWrapper"
import {analytics} from "./utils/firebase"
import {logEvent} from 'firebase/analytics';

const {Title} = Typography

export function useNavigateHome(page_link = '') {
    const location = useLocation()
    let page_title = location.pathname.substring(1)
    const navigate = useNavigate()
    console.log({page_title})

    useEffect(() => {
        if (page_title !== page_link) {
            console.log('redirecting')
            navigate(page_link)
        }
    }, [page_title])
}

export const CustomHeader: React.FC<any> = (
    {
        children,
        style
    }
) => {
    const isMobile = useIsMobile()

    return (
        <Title

            level={2}
            style={{
                // fontSize: '30px',
                fontWeight: 'bold',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: isMobile ? 'center' : 'left',
                paddingLeft: isMobile ? '10px' : undefined,
                paddingRight: isMobile ? '10px' : undefined,
                ...style
            }}>
            {children}
        </Title>
    )
}

const ChartPlot: React.FC = () => {
    const isMobile = useIsMobile()
    return (

        <img
            style={{
                width: '100%'
            }}
            src={ChartPlotData}
        />
    )
}

const ChartPlot2: React.FC = () => {
    const isMobile = useIsMobile()
    return (

        <img
            style={{
                width: '100%'
            }}
            src={ChartPlot2Data}
        />
    )
}
export const CustomP: React.FC<any> = (
    {
        children,
        style
    }
) => {
    const isMobile = useIsMobile()
    return (
        <p
            style={{
                fontSize: isMobile ? '18px' : '22px',
                color: 'grey',
                textAlign: isMobile ? 'center' : 'left',
                paddingLeft: '5vw',
                paddingRight: '5vw',
                ...style
            }}>
            {children}
        </p>
    )
}

export const CustomPWhite: React.FC<any> = (
    {
        children,
        style
    }
) => {
    return (
        <CustomP
            style={{
                color: 'white',
                ...style
            }}>
            {children}
        </CustomP>
    )
}

export const CustomHeader2: React.FC<any> = (
    {
        children,
        style,
        icon = null
    }
) => {
    const isMobile = useIsMobile()
    return (
        <p
            style={{
                fontSize: '24px',
                fontWeight: 'bold',
                color: 'black',
                textAlign: isMobile ? 'center' : 'left',
                ...style
            }}>
            {icon && <span style={{marginRight: '8px'}}>{icon}</span>}
            {children}
        </p>
    )
}

export const CustomP2: React.FC<any> = (
    {
        children,
        style
    }
) => {
    const isMobile = useIsMobile()
    return (
        <p
            style={{
                fontSize: isMobile ? '18px' : '20px',
                paddingLeft: isMobile ? '10px' : null,
                paddingRight: isMobile ? '10px' : null,

                color: 'grey',
                textAlign: isMobile ? 'center' : 'left',

                ...style
            }}>
            {children}
            <br/><br/>
        </p>
    )
}

export const CustomP2White: React.FC<any> = (
    {
        children,
        style
    }
) => {
    return (
        <CustomP2
            style={{
                color: '#efefefff',
                ...style
            }}>
            {children}
        </CustomP2>
    )
}

const Homepage: React.FC = () => {
    const isMobile = useIsMobile()
    return (
        <div style={{
            height: isMobile ? '73vh' : '83vh',
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundImage: `url(${BottomLeftBackround}), url(${TopRightBackground}), url(${PlayerSpike})`,
            backgroundPosition: isMobile ? 'calc(100% - 90vw) 10vh, 120vw -10vh, 70vw -1vh' : 'calc(100% - 90vw) 10vh, 85vw -10vh, 65vw 15vh',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundSize: isMobile ? '50vh, 45vh, 15vh' : '50vh, 45vh, 55vh',

        }}

        >

            <Title
                style={{
                    marginTop: '100px',
                    width: isMobile ? '80%' : '40%',
                    textAlign: 'center',
                }}
            >
                Unleash your volleyball spike with the power of AI
            </Title>
            <CustomP>

                Enhance your volleyball skills with advanced AI technology. {!isMobile && <br/>}
                Achieve unmatched accuracy and power in every spike
                <br/><br/>
            </CustomP>
            <ButtonDemo

                style={{
                    fontSize: '20px',
                    width: '250px',
                    height: '70px',
                    // fontWeight: 'italic'
                }}

            />


        </div>
    )
}

const Section: React.FC<any> = (
    {
        children,
        justifyContent = 'left',
        backgroundColor = colorPrimary,
    }
) => {
    const isMobile = useIsMobile()
    const justifyContent_ = isMobile ? 'center' : justifyContent
    return (
        <div style={{
            // height: heightGap,
            marginLeft: isMobile ? '1vh' : '3vh',
            marginRight: isMobile ? '1vh' : '3vh',
            marginBottom: isMobile ? '10vh' : '20vh',
            // marginTop: '30vh',
            display: 'flex',
            justifyContent: justifyContent_


        }}>
            <div
                style={{
                    backgroundColor: backgroundColor,
                    borderRadius: '10px',
                    display: 'flex',
                    width: '80%',
                    flexDirection: 'column',
                    paddingBottom: '5vh',
                    paddingTop: '2vh'

                }}
            >
                {children}
            </div>
        </div>
    )

}

function getHeightGap(height: number) {
    return {
        height: `${height}vh`,
        heightGap: `${height + blockGap}vh`
    }

}

const SectionChart: React.FC = () => {
    const isMobile = useIsMobile()
    const CustomHeaderValue: React.FC = () => {
        return (
            <>
                <CustomHeader
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'left',

                    }}

                >
                    Sports optimization platform
                </CustomHeader>
                <CustomPWhite

                    style={{
                        paddingLeft: isMobile ? '10vw' : null,
                        paddingRight: isMobile ? '10vw' : null,
                    }}

                >
                    Spaik uses advanced AI technology to enhance your spike technique, improve your performance, and
                    maximize your potential
                </CustomPWhite>
            </>
        )
    }
    return (
        <Section>
            <CustomHeader
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}

            >
                Sports optimization platform

            </CustomHeader>

            <CustomPWhite
                style={{
                    // width: '80%',
                    // color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    paddingLeft: '10vw',
                    paddingRight: '10vw',
                }}
            >
                Spaik uses advanced AI technology to enhance your spike technique, improve your performance, and
                maximize your potential
            </CustomPWhite>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    // width: '20vw'
                }}

            >
                {isMobile ?
                    <div
                        style={{
                            width: '60vw'
                        }}
                    >
                        <CustomHeader2
                            icon={<EyeOutlined/>}>
                            Track
                        </CustomHeader2>
                        <ChartPlot/>
                        <CustomHeader2
                            icon={<RadarChartOutlined/>}>
                            Pose
                        </CustomHeader2>

                        <ChartPlot2/>
                    </div> :
                    <Row
                        style={{
                            width: '60vw',
                            // flexDirection: 'column'

                        }}
                    >

                        <Col
                            offset={1}
                            span={11}>
                            <CustomHeader2
                                icon={<EyeOutlined/>}>
                                Track
                            </CustomHeader2>
                            <ChartPlot/>
                        </Col>
                        <Col
                            offset={1}
                            span={11}>
                            <CustomHeader2
                                icon={<RadarChartOutlined/>}>
                                Pose
                            </CustomHeader2>

                            <ChartPlot2/>
                        </Col>
                    </Row>
                }
            </div>

        </Section>


    )
}

const SectionBug: React.FC = () => {
    const isMobile = useIsMobile()


    const MarketingData: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<BarChartOutlined/>}>
                    Performance data
                </CustomHeader2>
                <CustomP2>
                    Spaik analyzes your spike metrics, training sessions, game footage, and any other volleyball
                    activities you track
                </CustomP2>
            </>
        )
    }

    const RevenueData: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<LineChartOutlined/>}>
                    Technique data
                </CustomHeader2>
                <CustomP2>
                    Include your performance data and Spaik will identify the causal relationships with your spike
                    technique
                </CustomP2>
            </>
        )

    }

    const ExternalFactors: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<CloudOutlined/>}>
                    External factors
                </CustomHeader2>
                <CustomP2>
                    Any external factor that can be measured and tracked can be added to the model: weather, opponent
                    data, game conditions
                </CustomP2>
            </>
        )
    }

    const ROI: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<RiseOutlined/>}>
                    Performance insights
                </CustomHeader2>
                <CustomP2>
                    Spaik calculates the true impact of each factor on your spike, understanding the cross-training
                    effects
                </CustomP2>
            </>
        )
    }

    const BudgetOptimizer: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<FundOutlined/>}>
                    Training optimizer
                </CustomHeader2>
                <CustomP2>
                    Spaik optimizes your training regimen, so with the same effort you get +20% improvement in
                    performance
                </CustomP2>
            </>
        )
    }

    const ActionPlan: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<SolutionOutlined/>}>
                    Action plan
                </CustomHeader2>
                <CustomP2>
                    Spaik builds for you multiple improvement scenarios so you can choose the ones with the best
                    outcomes
                </CustomP2>
            </>
        )
    }


    return (
        <Section justifyContent='end'
                 backgroundColor='whitesmoke'
        >

            <CustomHeader
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}

            >
                Measure and improve
            </CustomHeader>


            {isMobile ?
                <>
                    <MarketingData/>
                    <RevenueData/>
                    <ExternalFactors/>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'

                        }}
                    >
                        <img
                            style={{width: '90%'}}

                            src={MmmIcon}/>
                    </div>
                    <ROI/>
                    <BudgetOptimizer/>
                    <ActionPlan/>
                </>
                :
                <Row>
                    <Col span={7}
                         offset={1}
                    >

                        <MarketingData/>
                        <RevenueData/>
                        <ExternalFactors/>
                    </Col>
                    <Col span={7}
                         offset={1}
                    >
                        <img
                            style={{width: '90%'}}

                            src={MmmIcon}/>
                    </Col>
                    <Col span={6}
                         offset={1}
                    >
                        <ROI/>
                        <BudgetOptimizer/>
                        <ActionPlan/>
                    </Col>
                </Row>
            }
        </Section>
    )
}


const SectionCookieLess: React.FC = () => {
    const {height, heightGap} = getHeightGap(50)

    const isMobile = useIsMobile()

    const CookielessFuture: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<SafetyOutlined/>}>
                    Advanced performance insights
                </CustomHeader2>
                <CustomP2White>
                    Spaik's AI analyzes detailed data from your training and games. This ensures precise, personalized
                    recommendations to enhance your spike technique and overall performance
                </CustomP2White>
            </>
        )
    }

    const AffordableAi: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<DollarOutlined/>}>
                    Affordable Ai
                </CustomHeader2>
                <CustomP2White>
                    AI is more affordable than ever. <br/>
                    Spaik includes a powerful AI engine to run countless simulations and identify the best improvement
                    scenarios for you, all at a reasonable cost.
                </CustomP2White>
            </>
        )
    }

    const UnifiedData: React.FC = () => {
        return (
            <>
                <CustomHeader2
                    icon={<DatabaseOutlined/>}>
                    Unified data
                </CustomHeader2>
                <CustomP2White>
                    AI-powered training needs data to work. Data is now more accessible than ever. Spaik integrates
                    curated data from all your training sessions, games, and even external conditions to provide
                    comprehensive insights.
                </CustomP2White>
            </>
        )
    }
    return (
        <Section>
            <CustomHeader
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}

            >
                Get ready to spike with precision thanks to AI technology
            </CustomHeader>

            <CustomPWhite
                style={{
                    // width: '80%',
                    // color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    paddingLeft: '10vw',
                    paddingRight: '10vw',
                }}
            >
                Spaik harnesses the power of advanced AI algorithms to provide
                the best spike improvement recommendations through comprehensive performance analysis
            </CustomPWhite>

            {isMobile ?
                <>
                    <CookielessFuture/>
                    <AffordableAi/>
                    <UnifiedData/>

                </> :
                <Row>
                    <Col span={7}
                         offset={1}
                    >
                        <CookielessFuture/>

                    </Col>
                    <Col
                        span={7}
                        offset={1}
                    >

                        <AffordableAi/>
                    </Col>
                    <Col
                        span={6}
                        offset={1}

                    >
                        <UnifiedData/>
                    </Col>


                </Row>
            }
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // marginTop: '20px',
                    width: '100%'
                }}
            >

                <img
                    style={{

                        width: isMobile ? '40vw' : '10vw'
                    }}

                    src={CookielessIcon}/>
            </div>
        </Section>
    )
}

const Bottom: React.FC = () => {
    const isMobile = useIsMobile()
    return (
        <div
            style={{
                // height: isMobile ? '20vh' : '87vh',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                marginBottom: isMobile ? '10vh' : '20vh',
            }}

        >
            <ButtonDemo
                style={{
                    fontSize: '20px',
                    width: '250px',
                    height: '70px',
                    // fontWeight: 'italic'
                }}/>
        </div>
    )
}
const App: React.FC = () => {
    useNavigateHome()
    logEvent(analytics, 'page_view', {page_title: 'homepage'})

    const repo_name = "ds-spaik-homepage"
    const [textDisplay, setTextDisplay] = useState<boolean>(false);
    return (
        <div>
            <Homepage/>


            <SectionChart/>
            <SectionBug/>

            <SectionCookieLess/>

            <Bottom/>

        </div>
    )

};
export default App;